import styled, { keyframes } from 'styled-components';

const spinner = keyframes`
0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

interface IPreloader {
  $show: boolean;
}
export const Preloader = styled.div<IPreloader>`
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.7);
`;

export const LoadingSpinner = styled.div`
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;

  div {
    transform-origin: 40px 40px;
    animation: ${spinner} 1.5s linear infinite;

    &:nth-child(1) {
      transform: rotate(90deg);
      animation-delay: -1.1s;
    }
    &:nth-child(2) {
      transform: rotate(135deg);
      animation-delay: -1s;
    }
    &:nth-child(3) {
      transform: rotate(180deg);
      animation-delay: -0.9s;
    }
    &:nth-child(4) {
      transform: rotate(270deg);
      animation-delay: -0.8s;
    }
    &:nth-child(5) {
      transform: rotate(315deg);
      animation-delay: -0.7s;
    }
    &:nth-child(6) {
      transform: rotate(0deg);
      animation-delay: -0.6s;
    }
    &:nth-child(7) {
      transform: rotate(45deg);
      animation-delay: -0.5s;
    }

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      left: 38px;
      width: 3px;
      height: 30px;
      background: #22425c;
    }

    &.longo:after {
      background: #e5695b;
      height: 80px;
    }
  }
`;

/**
 * Loader component
 *
 * @author Lucas Santana | Leme Inteligência Forense
 * @return JSX.Element
 */

export function LemeLoader({ $show }: IPreloader) {
  return (
    <Preloader $show={$show}>
      <LoadingSpinner>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div className="longo" />
      </LoadingSpinner>
    </Preloader>
  );
}
