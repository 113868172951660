import loadable from '@loadable/component';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import { AppProps } from 'next/app';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { DefaultSeo } from 'next-seo';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import { setCookie, parseCookies } from 'nookies';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import * as gtag from '../scripts/gtag';
import * as fbq from '../scripts/fbpixel';
import { theme } from '../styles/theme';
import GlobalStyles from '../styles/global';
import Pixel from '../src/components/Pixel';
import SEO from '../next-seo.config';
import Analytics from '../src/components/Analytics';

/**
 * Custom App component
 *
 * @author Lucas Santana | Leme Inteligência Forense
 * @returns JSX.Element
 */

// Lazy loading components
const Layout = loadable(
  () => import(/* webpackChunkName: "Layout" */ '../src/components/Layout'),
);

export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
  const cookies = parseCookies();
  const [token, setToken] = useState(cookies['tj-lif']);
  const tagManagerArgs: TagManagerArgs = {
    gtmId,
  };

  // Start Google Analytics and Facebook Pixel
  useEffect(() => {
    fbq.pageview();

    const handleRouteChange = (url: any) => {
      gtag.pageview(url);
      fbq.pageview();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // Start Google Tag Manager
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  // Check JWT Token
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const decoded = jwt.decode(token) || 60000 * 15; // 15 Minutes
    let tokenGenerationInProgress = false; // Flag to track if token generation is already in progress

    const generateToken = () => {
      axios
        .get(`${process.env.NEXT_PUBLIC_JWT_ENDPOINT}`)
        .then((response) => {
          setCookie(null, 'tj-lif', response.data.token, {
            path: '/',
          });
          setToken(response.data.token);
          tokenGenerationInProgress = false; // Reset the flag after successful token generation
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.toJSON());
          tokenGenerationInProgress = false; // Reset the flag after token generation failure
        });
    };

    const scheduleTokenGeneration = () => {
      const expiresIn = decoded.exp - Date.now() / 1000;
      timeoutId = setTimeout(generateToken, expiresIn * 1000);
    };

    // If token cookie is not created, request one and schedule refresh when it expires
    if (!token) {
      if (!tokenGenerationInProgress) {
        tokenGenerationInProgress = true;
        generateToken();
      }
    } else {
      // Refresh token if it's expired
      // eslint-disable-next-line no-lonely-if
      if (!tokenGenerationInProgress) {
        tokenGenerationInProgress = true;
        scheduleTokenGeneration();
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [token]);

  return (
    <>
      <DefaultSeo {...SEO} />

      <ThemeProvider theme={theme}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
          scriptProps={{
            async: false,
            defer: false,
            appendTo: 'head',
            nonce: undefined,
          }}
        >
          <GlobalStyles theme={theme} />

          <Layout>
            <Component {...pageProps} />
          </Layout>

          <Pixel />
          <Analytics />
        </GoogleReCaptchaProvider>
      </ThemeProvider>
    </>
  );
}
