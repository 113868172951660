import loadable from '@loadable/component';
import { NextSeo } from 'next-seo';

import Hero from './Hero';
import Newsletter from '../../components/Newsletter';

/**
 * Home Screen
 *
 * @author Lucas Santana | Leme Inteligência Forense
 * @returns JSX.Element
 */

// Lazy loading components
const Section = loadable((props: any) => import(`./${props.id}`));

export default function HomeScreen() {
  return (
    <>
      <NextSeo
        title="LEME – Tecnologia e serviços para recuperação de ativos"
        description="A LEME Inteligência Forense oferece soluções em tecnologia e serviços para pesquisa de bens e recuperação de ativos. Conheça a Plataforma SONAR."
        openGraph={{
          title: 'LEME – Tecnologia e serviços para recuperação de ativos',
          description:
            'A LEME Inteligência Forense oferece soluções em tecnologia e serviços para pesquisa de bens e recuperação de ativos. Conheça a Plataforma SONAR.',
        }}
      />
      <Hero />
      <Section id="Investigation" />
      <Section id="Solutions" />
      <Section id="Confidence" />
      <Section id="Assistant" />
      <Section id="Credit" />
      <Section id="Platform" />
      <Section id="Plans" />
      <Section id="Services" />
      <Section id="News" />
      <Section id="Blog" />
      <Section id="Contact" />
      <Newsletter />
    </>
  );
}
