import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

interface ILemeModalOverlay {
  open: boolean;
}
export const ModalOverlay = styled.div<ILemeModalOverlay>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(247, 246, 245, 0.3);
  backdrop-filter: blur(3px);
  z-index: 100;
  animation: ${fadeIn} 0.1s ease-in-out;
`;

export const Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  padding: 40px;
  max-width: 850px;
  width: 100%;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.gray025};
  border: 1px solid ${({ theme }) => theme.colors.gray100};
  box-shadow: 0px 4px 20px #1e2c3833;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 95vw;
  }
`;

export const ModalTitle = styled.h4`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: 500;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.gray900};
  margin-bottom: 24px;
`;

export const ModalBody = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  margin-bottom: 24px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
`;

/**
 * Modal component
 *
 * @author Lucas Santana | Leme Inteligência Forense
 * @return JSX.Element
 */

interface ILemeModal {
  show: boolean;
  children: React.ReactNode;
  handleClose?: () => void;
}
export function LemeModal({
  show,
  children,
  handleClose,
}: ILemeModal) {
  return (
    <ModalOverlay open={show} onClick={handleClose}>
      <Modal onClick={(e) => e.stopPropagation()}>{children}</Modal>
    </ModalOverlay>
  );
}
