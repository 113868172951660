'use client';

import { parseCookies } from 'nookies';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as fbq from '../../../scripts/fbpixel';
import * as C from './styles';
import * as M from '../Modal/styles';
import * as S from '../../../styles/shared';
import { LemeLoader } from '../Loader/styles';
import { LemeToast, ToastButton } from '../Toast/styles';
import * as rd from '../../../scripts/rdstation';

type FormData = {
  newsletterEmail: string;
};

/**
 * Newsletter Section
 *
 * @author Lucas Santana | Leme Inteligência Forense
 * @returns JSX.Element
 */

export default function Newsletter() {
  const cookies = parseCookies();
  const token = cookies['tj-lif'];
  const [modal, setModal] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    mode: 'onSubmit',
    shouldUseNativeValidation: false,
  });

  // Show Toast
  const handleShowToast = () => {
    setToast(true);

    setTimeout(() => {
      setToast(false);
    }, 10000);
  };

  // Hide Toast
  const handleCloseToast = () => {
    setToast(false);
  };

  // Submit form to backend
  const onSubmit = async (object: any, event: any) => {
    try {
      const { status, data } = await axios.post(
        process.env.NEXT_PUBLIC_NEWSLETTER_ENDPOINT,
        object,
        {
          headers: {
            Authorization: token,
          },
        },
      );

      // Send RD Station API Conversion Notification
      rd.sendConversionNotification({
        conversion_identifier: 'Newsletter - Leme',
        email: object.newsletterEmail,
      });

      // Send Facebook Pixel Conversion Notification
      fbq.event('CompleteRegistration', {
        content_name: 'Newsletter - Leme',
        content_category: 'Cadastro na Newsletter',
        email: object.newsletterEmail,
      });

      // Toast settings
      setToastMessage('E-mail cadastrado com sucesso.');
      setToastType('success');

      // eslint-disable-next-line no-console
      console.log(status, data.message);
    } catch (err) {
      // Email already in database show success Toast, but it doesnt save again in db
      if (err.response.status === 409) {
        // Send RD Station API Conversion Notification
        rd.sendConversionNotification({
          conversion_identifier: 'Newsletter - Leme',
          email: object.newsletterEmail,
        });

        // Send Facebook Pixel Conversion Notification
        fbq.event('CompleteRegistration', {
          content_name: 'Newsletter - Leme',
          content_category: 'Cadastro na Newsletter',
          email: object.newsletterEmail,
        });

        setToastMessage('E-mail cadastrado com sucesso.');
        setToastType('success');
      } else {
        setToastMessage('Ocorreu um erro ao processar o pedido.');
        setToastType('error');
      }

      // eslint-disable-next-line no-console
      console.log(err.response.status, err.response.data.message);
    }

    handleShowToast();
    event.target.reset();
  };

  // Open Modal
  const handleOpenModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setModal(true);
  };

  // Close Modal
  const handleCloseModal = () => {
    setModal(false);
  };

  // Lock page scroll if overlay object is open
  const handleScroll = () => {
    // Prevent not browser ambient to get undefined document element
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      modal
        ? document.querySelector('body').classList.add('overlay')
        : document.querySelector('body').classList.remove('overlay');
    }
  };

  const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // Lock page scroll opened modal
  useEffect(() => {
    handleScroll();
  }, [modal]);

  return (
    <C.Section>
      <LemeLoader $show={isSubmitting} />

      <LemeToast $show={toast} type={toastType}>
        {toastMessage}
        <ToastButton onClick={handleCloseToast}>&times;</ToastButton>
      </LemeToast>

      <div className="container-inner">
        <C.Container>
          <S.Title $small>Newsletter</S.Title>
          <p>
            Inscreva-se para receber conteúdos relevantes sobre investigação e
            recuperação de ativos.
          </p>

          <form onSubmit={handleSubmit(onSubmit)} id="newsletter-form-leme">
            <C.InputWrapper>
              <div className="form-group">
                <S.Label htmlFor="newsletter-email">E-mail</S.Label>

                <S.Input
                  type="email"
                  name="newsletterEmail"
                  id="newsletter-email"
                  placeholder="Seu melhor e-mail"
                  {...register('newsletterEmail', {
                    required: 'Insira um endereço de e-mail válido.',
                    validate: (value) =>
                      emailRegEx.test(value) ||
                      'Insira um endereço de e-mail válido.',
                  })}
                  className={`${errors.newsletterEmail ? 'input-error' : ''}`}
                />
              </div>

              <S.Button
                as="button"
                type="submit"
                $small
                disabled={isSubmitting}
                href="#"
              >
                Enviar
              </S.Button>
            </C.InputWrapper>

            <S.InputMessage>
              {errors.newsletterEmail ? errors.newsletterEmail.message : ''}
            </S.InputMessage>

            <C.InputLegend>
              Ao se inscrever em nossa newsletter, você concorda com nossos
              <C.ModalToggler onClick={handleOpenModal}>
                termos e condições.
              </C.ModalToggler>
            </C.InputLegend>
          </form>
        </C.Container>
      </div>

      <M.LemeModal show={modal} handleClose={handleCloseModal}>
        <M.ModalTitle>Termos de Uso da Newsletter</M.ModalTitle>

        <M.ModalBody>
          1. Ao se inscrever na nossa newsletter, você concorda em receber
          e-mails regulares com conteúdo sobre o mercado de recuperação de
          crédito e material informativo e de marketing sobre a LEME.
          <br />
          <br />
          2. A assinatura da newsletter é gratuita. Você pode cancelar a sua
          inscrição a qualquer momento, bastando clicar no link de cancelamento
          de inscrição incluído em cada e-mail que enviarmos.
          <br />
          <br />
          3. A LEME reserva o direito de alterar o conteúdo e a frequência das
          newsletters a qualquer momento.
          <br />
          <br />
          4. A LEME não compartilhará o seu endereço de e-mail com terceiros sem
          o seu consentimento.
          <br />
          <br />
          5. Usaremos o seu endereço de e-mail apenas para enviar a nossa
          newsletter e para entrar em contato com você caso seja necessário.
          <br />
          <br />
          6. Se você tiver alguma dúvida ou preocupação em relação aos termos de
          uso da newsletter, entre em contato conosco enviando um e-mail para
          privacidade@lemeforense.com.br.
          <br />
          <br />
          7. Ao se inscrever na nossa newsletter, você concorda com nossos
          termos de uso. Se você não concordar com estes termos, por favor, não
          se inscreva em nossa newsletter. Obrigado por se inscrever na nossa
          newsletter! Estamos ansiosos para compartilhar conteúdo para lhe
          oferecer o leme rumo ao sucesso.
        </M.ModalBody>

        <M.ModalFooter>
          <S.Button as="button" href="#" onClick={handleCloseModal} $small>
            Fechar
          </S.Button>
        </M.ModalFooter>
      </M.LemeModal>
    </C.Section>
  );
}
